* {
  cursor: url("./mymelody.png"), auto;
  font-family: "Syne", sans-serif;
  font-weight: bold;
}
.App-header {
  text-align: center;
}

.headline {
  text-align: center;
}
.navigation {
  padding: 1em;
  align-self: flex-start;
  margin: 1em;
}

.logo {
  width: 30%;
  height: 30%;
}

.logo-nav {
  width: 10%;
  height: 10%;
  text-align: center;
}

.navigation-links {
  text-decoration: none;
  background-color: transparent;
  border: 1px solid black;
  color: green;
  text-align: center;
  margin: 0 1em;
}

.links-navigation {
  margin-top: 2em;
}

.links {
  margin-top: 2em;
  text-decoration: none !important;
}

.app-image {
  width: 100%;
}

#banner-image {
  max-height: 80%;
  max-width: 90%;
  min-height: 65%;
  min-width: 80%;
  text-align: center;
  margin: 1em auto;
}

.banner-container {
  height: 100%;
  width: 100%;
  text-align: center;
  /* margin: 1em auto; */
  display: flex;
}

.front-page-container {
  width: 100%;
  font-family: "Times New Roman", Times, serif;
  background-color: green;
  text-align: center;
}

#reading-now-title {
  text-align: left;
  font-family: "mistoregular";
  margin-bottom: 2em;
  margin-left: 1em;
}

#reading-now-container {
  margin-top: 1.5em;
  font-family: "Times New Roman", Times, serif;
}

.app-container {
  height: 100vh;
}

#root {
  height: 100vh;
}

#gallery-image {
  height: 20%;
  width: 20%;
}
